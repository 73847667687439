export default [
    {
        "label": "中国 (China)",
        "children": [
            {
                "label": "北京 (Beijing)",
                "value": "Asia/Shanghai",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Australia",
        "children": [
            {
                "label": "Lord Howe",
                "value": "Australia/Lord_Howe",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Macquarie",
                "value": "Antarctica/Macquarie",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Hobart",
                "value": "Australia/Hobart",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Melbourne",
                "value": "Australia/Melbourne",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Sydney",
                "value": "Australia/Sydney",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Broken Hill",
                "value": "Australia/Broken_Hill",
                "time": "13:25",
                "offset": "GMT +10:30"
            },
            {
                "label": "Brisbane",
                "value": "Australia/Brisbane",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Lindeman",
                "value": "Australia/Lindeman",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Adelaide",
                "value": "Australia/Adelaide",
                "time": "13:25",
                "offset": "GMT +10:30"
            },
            {
                "label": "Darwin",
                "value": "Australia/Darwin",
                "time": "12:25",
                "offset": "GMT +09:30"
            },
            {
                "label": "Perth",
                "value": "Australia/Perth",
                "time": "10:55",
                "offset": "GMT +08:00"
            },
            {
                "label": "Eucla",
                "value": "Australia/Eucla",
                "time": "11:40",
                "offset": "GMT +08:45"
            }
        ]
    },
    {
        "label": "India",
        "children": [
            {
                "label": "Kolkata",
                "value": "Asia/Kolkata",
                "time": "08:25",
                "offset": "GMT +05:30"
            }
        ]
    },
    {
        "label": "Iran",
        "children": [
            {
                "label": "Tehran",
                "value": "Asia/Tehran",
                "time": "06:25",
                "offset": "GMT +03:30"
            }
        ]
    },
    {
        "label": "United States",
        "children": [
            {
                "label": "New York",
                "value": "America/New_York",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Detroit",
                "value": "America/Detroit",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Louisville, Kentucky",
                "value": "America/Kentucky/Louisville",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Monticello, Kentucky",
                "value": "America/Kentucky/Monticello",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Indianapolis, Indiana",
                "value": "America/Indiana/Indianapolis",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Vincennes, Indiana",
                "value": "America/Indiana/Vincennes",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Winamac, Indiana",
                "value": "America/Indiana/Winamac",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Marengo, Indiana",
                "value": "America/Indiana/Marengo",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Petersburg, Indiana",
                "value": "America/Indiana/Petersburg",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Vevay, Indiana",
                "value": "America/Indiana/Vevay",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Chicago",
                "value": "America/Chicago",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Tell City, Indiana",
                "value": "America/Indiana/Tell_City",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Knox, Indiana",
                "value": "America/Indiana/Knox",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Menominee",
                "value": "America/Menominee",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Center, North Dakota",
                "value": "America/North_Dakota/Center",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "New Salem, North Dakota",
                "value": "America/North_Dakota/New_Salem",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Beulah, North Dakota",
                "value": "America/North_Dakota/Beulah",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Denver",
                "value": "America/Denver",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Boise",
                "value": "America/Boise",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Phoenix",
                "value": "America/Phoenix",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Los Angeles",
                "value": "America/Los_Angeles",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Anchorage",
                "value": "America/Anchorage",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Juneau",
                "value": "America/Juneau",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Sitka",
                "value": "America/Sitka",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Metlakatla",
                "value": "America/Metlakatla",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Yakutat",
                "value": "America/Yakutat",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Nome",
                "value": "America/Nome",
                "time": "18:55",
                "offset": "GMT -08:00"
            },
            {
                "label": "Adak",
                "value": "America/Adak",
                "time": "17:55",
                "offset": "GMT -09:00"
            },
            {
                "label": "Honolulu",
                "value": "Pacific/Honolulu",
                "time": "16:55",
                "offset": "GMT -10:00"
            }
        ]
    },
    {
        "label": "New Zealand",
        "children": [
            {
                "label": "Auckland",
                "value": "Pacific/Auckland",
                "time": "15:55",
                "offset": "GMT +13:00"
            },
            {
                "label": "Chatham",
                "value": "Pacific/Chatham",
                "time": "16:40",
                "offset": "GMT +13:45"
            }
        ]
    },
    {
        "label": "Britain (UK)",
        "children": [
            {
                "label": "London",
                "value": "Europe/London",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Afghanistan",
        "children": [
            {
                "label": "Kabul",
                "value": "Asia/Kabul",
                "time": "07:25",
                "offset": "GMT +04:30"
            }
        ]
    },
    {
        "label": "Albania",
        "children": [
            {
                "label": "Tirane",
                "value": "Europe/Tirane",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Algeria",
        "children": [
            {
                "label": "Algiers",
                "value": "Africa/Algiers",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Andorra",
        "children": [
            {
                "label": "Andorra",
                "value": "Europe/Andorra",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Angola",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Anguilla",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Antarctica",
        "children": [
            {
                "label": "Casey",
                "value": "Antarctica/Casey",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Davis",
                "value": "Antarctica/Davis",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Mawson",
                "value": "Antarctica/Mawson",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Palmer",
                "value": "Antarctica/Palmer",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Rothera",
                "value": "Antarctica/Rothera",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Troll",
                "value": "Antarctica/Troll",
                "time": "02:55",
                "offset": "GMT +00:00"
            },
            {
                "label": "Urumqi",
                "value": "Asia/Urumqi",
                "time": "08:55",
                "offset": "GMT +06:00"
            },
            {
                "label": "Auckland",
                "value": "Pacific/Auckland",
                "time": "15:55",
                "offset": "GMT +13:00"
            },
            {
                "label": "Port Moresby",
                "value": "Pacific/Port_Moresby",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Riyadh",
                "value": "Asia/Riyadh",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Antigua & Barbuda",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Argentina",
        "children": [
            {
                "label": "Buenos Aires, Argentina",
                "value": "America/Argentina/Buenos_Aires",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Cordoba, Argentina",
                "value": "America/Argentina/Cordoba",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Salta, Argentina",
                "value": "America/Argentina/Salta",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Jujuy, Argentina",
                "value": "America/Argentina/Jujuy",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Tucuman, Argentina",
                "value": "America/Argentina/Tucuman",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Catamarca, Argentina",
                "value": "America/Argentina/Catamarca",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "La Rioja, Argentina",
                "value": "America/Argentina/La_Rioja",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "San Juan, Argentina",
                "value": "America/Argentina/San_Juan",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Mendoza, Argentina",
                "value": "America/Argentina/Mendoza",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "San Luis, Argentina",
                "value": "America/Argentina/San_Luis",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Rio Gallegos, Argentina",
                "value": "America/Argentina/Rio_Gallegos",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Ushuaia, Argentina",
                "value": "America/Argentina/Ushuaia",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Armenia",
        "children": [
            {
                "label": "Yerevan",
                "value": "Asia/Yerevan",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Aruba",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Austria",
        "children": [
            {
                "label": "Vienna",
                "value": "Europe/Vienna",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Azerbaijan",
        "children": [
            {
                "label": "Baku",
                "value": "Asia/Baku",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Bahamas",
        "children": [
            {
                "label": "Toronto",
                "value": "America/Toronto",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Bahrain",
        "children": [
            {
                "label": "Qatar",
                "value": "Asia/Qatar",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Bangladesh",
        "children": [
            {
                "label": "Dhaka",
                "value": "Asia/Dhaka",
                "time": "08:55",
                "offset": "GMT +06:00"
            }
        ]
    },
    {
        "label": "Barbados",
        "children": [
            {
                "label": "Barbados",
                "value": "America/Barbados",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Belarus",
        "children": [
            {
                "label": "Minsk",
                "value": "Europe/Minsk",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Belgium",
        "children": [
            {
                "label": "Brussels",
                "value": "Europe/Brussels",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Belize",
        "children": [
            {
                "label": "Belize",
                "value": "America/Belize",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Benin",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Bermuda",
        "children": [
            {
                "label": "Bermuda",
                "value": "Atlantic/Bermuda",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Bhutan",
        "children": [
            {
                "label": "Thimphu",
                "value": "Asia/Thimphu",
                "time": "08:55",
                "offset": "GMT +06:00"
            }
        ]
    },
    {
        "label": "Bolivia",
        "children": [
            {
                "label": "La Paz",
                "value": "America/La_Paz",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Bosnia & Herzegovina",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Botswana",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Brazil",
        "children": [
            {
                "label": "Noronha",
                "value": "America/Noronha",
                "time": "00:55",
                "offset": "GMT -02:00"
            },
            {
                "label": "Belem",
                "value": "America/Belem",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Fortaleza",
                "value": "America/Fortaleza",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Recife",
                "value": "America/Recife",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Araguaina",
                "value": "America/Araguaina",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Maceio",
                "value": "America/Maceio",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Bahia",
                "value": "America/Bahia",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Sao Paulo",
                "value": "America/Sao_Paulo",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Campo Grande",
                "value": "America/Campo_Grande",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Cuiaba",
                "value": "America/Cuiaba",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Santarem",
                "value": "America/Santarem",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Porto Velho",
                "value": "America/Porto_Velho",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Boa Vista",
                "value": "America/Boa_Vista",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Manaus",
                "value": "America/Manaus",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Eirunepe",
                "value": "America/Eirunepe",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Rio Branco",
                "value": "America/Rio_Branco",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "British Indian Ocean Territory",
        "children": [
            {
                "label": "Chagos",
                "value": "Indian/Chagos",
                "time": "08:55",
                "offset": "GMT +06:00"
            }
        ]
    },
    {
        "label": "Brunei",
        "children": [
            {
                "label": "Kuching",
                "value": "Asia/Kuching",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Bulgaria",
        "children": [
            {
                "label": "Sofia",
                "value": "Europe/Sofia",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Burkina Faso",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Burundi",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Cambodia",
        "children": [
            {
                "label": "Bangkok",
                "value": "Asia/Bangkok",
                "time": "09:55",
                "offset": "GMT +07:00"
            }
        ]
    },
    {
        "label": "Cameroon",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Canada",
        "children": [
            {
                "label": "St Johns",
                "value": "America/St_Johns",
                "time": "00:25",
                "offset": "GMT -03:30"
            },
            {
                "label": "Halifax",
                "value": "America/Halifax",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Glace Bay",
                "value": "America/Glace_Bay",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Moncton",
                "value": "America/Moncton",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Goose Bay",
                "value": "America/Goose_Bay",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Toronto",
                "value": "America/Toronto",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Iqaluit",
                "value": "America/Iqaluit",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Winnipeg",
                "value": "America/Winnipeg",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Resolute",
                "value": "America/Resolute",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Rankin Inlet",
                "value": "America/Rankin_Inlet",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Regina",
                "value": "America/Regina",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Swift Current",
                "value": "America/Swift_Current",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Edmonton",
                "value": "America/Edmonton",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Cambridge Bay",
                "value": "America/Cambridge_Bay",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Inuvik",
                "value": "America/Inuvik",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Dawson Creek",
                "value": "America/Dawson_Creek",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Fort Nelson",
                "value": "America/Fort_Nelson",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Whitehorse",
                "value": "America/Whitehorse",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Dawson",
                "value": "America/Dawson",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Vancouver",
                "value": "America/Vancouver",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Panama",
                "value": "America/Panama",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            },
            {
                "label": "Phoenix",
                "value": "America/Phoenix",
                "time": "19:55",
                "offset": "GMT -07:00"
            }
        ]
    },
    {
        "label": "Cape Verde",
        "children": [
            {
                "label": "Cape Verde",
                "value": "Atlantic/Cape_Verde",
                "time": "01:55",
                "offset": "GMT -01:00"
            }
        ]
    },
    {
        "label": "Caribbean NL",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Cayman Islands",
        "children": [
            {
                "label": "Panama",
                "value": "America/Panama",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Central African Rep.",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Chad",
        "children": [
            {
                "label": "Ndjamena",
                "value": "Africa/Ndjamena",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Chile",
        "children": [
            {
                "label": "Santiago",
                "value": "America/Santiago",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Punta Arenas",
                "value": "America/Punta_Arenas",
                "time": "23:55",
                "offset": "GMT -03:00"
            },
            {
                "label": "Easter",
                "value": "Pacific/Easter",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Christmas Island",
        "children": [
            {
                "label": "Bangkok",
                "value": "Asia/Bangkok",
                "time": "09:55",
                "offset": "GMT +07:00"
            }
        ]
    },
    {
        "label": "Cocos (Keeling) Islands",
        "children": [
            {
                "label": "Yangon",
                "value": "Asia/Yangon",
                "time": "09:25",
                "offset": "GMT +06:30"
            }
        ]
    },
    {
        "label": "Colombia",
        "children": [
            {
                "label": "Bogota",
                "value": "America/Bogota",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Comoros",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Congo (Dem. Rep.)",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            },
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Congo (Rep.)",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Cook Islands",
        "children": [
            {
                "label": "Rarotonga",
                "value": "Pacific/Rarotonga",
                "time": "16:55",
                "offset": "GMT -10:00"
            }
        ]
    },
    {
        "label": "Costa Rica",
        "children": [
            {
                "label": "Costa Rica",
                "value": "America/Costa_Rica",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Croatia",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Cuba",
        "children": [
            {
                "label": "Havana",
                "value": "America/Havana",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Curaçao",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Cyprus",
        "children": [
            {
                "label": "Nicosia",
                "value": "Asia/Nicosia",
                "time": "04:55",
                "offset": "GMT +02:00"
            },
            {
                "label": "Famagusta",
                "value": "Asia/Famagusta",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Czech Republic",
        "children": [
            {
                "label": "Prague",
                "value": "Europe/Prague",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Côte d'Ivoire",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Denmark",
        "children": [
            {
                "label": "Berlin",
                "value": "Europe/Berlin",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Djibouti",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Dominica",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Dominican Republic",
        "children": [
            {
                "label": "Santo Domingo",
                "value": "America/Santo_Domingo",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "East Timor",
        "children": [
            {
                "label": "Dili",
                "value": "Asia/Dili",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Ecuador",
        "children": [
            {
                "label": "Guayaquil",
                "value": "America/Guayaquil",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Galapagos",
                "value": "Pacific/Galapagos",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Egypt",
        "children": [
            {
                "label": "Cairo",
                "value": "Africa/Cairo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "El Salvador",
        "children": [
            {
                "label": "El Salvador",
                "value": "America/El_Salvador",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Equatorial Guinea",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Eritrea",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Estonia",
        "children": [
            {
                "label": "Tallinn",
                "value": "Europe/Tallinn",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Eswatini (Swaziland)",
        "children": [
            {
                "label": "Johannesburg",
                "value": "Africa/Johannesburg",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Ethiopia",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Falkland Islands",
        "children": [
            {
                "label": "Stanley",
                "value": "Atlantic/Stanley",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Faroe Islands",
        "children": [
            {
                "label": "Faroe",
                "value": "Atlantic/Faroe",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Fiji",
        "children": [
            {
                "label": "Fiji",
                "value": "Pacific/Fiji",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Finland",
        "children": [
            {
                "label": "Helsinki",
                "value": "Europe/Helsinki",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "France",
        "children": [
            {
                "label": "Paris",
                "value": "Europe/Paris",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "French Guiana",
        "children": [
            {
                "label": "Cayenne",
                "value": "America/Cayenne",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "French Polynesia",
        "children": [
            {
                "label": "Tahiti",
                "value": "Pacific/Tahiti",
                "time": "16:55",
                "offset": "GMT -10:00"
            },
            {
                "label": "Marquesas",
                "value": "Pacific/Marquesas",
                "time": "17:25",
                "offset": "GMT -10:30"
            },
            {
                "label": "Gambier",
                "value": "Pacific/Gambier",
                "time": "17:55",
                "offset": "GMT -09:00"
            }
        ]
    },
    {
        "label": "French S. Terr.",
        "children": [
            {
                "label": "Dubai",
                "value": "Asia/Dubai",
                "time": "06:55",
                "offset": "GMT +04:00"
            },
            {
                "label": "Maldives",
                "value": "Indian/Maldives",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Gabon",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Gambia",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Georgia",
        "children": [
            {
                "label": "Tbilisi",
                "value": "Asia/Tbilisi",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Germany",
        "children": [
            {
                "label": "Berlin",
                "value": "Europe/Berlin",
                "time": "03:55",
                "offset": "GMT +01:00"
            },
            {
                "label": "Zurich",
                "value": "Europe/Zurich",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Ghana",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Gibraltar",
        "children": [
            {
                "label": "Gibraltar",
                "value": "Europe/Gibraltar",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Greece",
        "children": [
            {
                "label": "Athens",
                "value": "Europe/Athens",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Greenland",
        "children": [
            {
                "label": "Nuuk",
                "value": "America/Nuuk",
                "time": "00:55",
                "offset": "GMT -02:00"
            },
            {
                "label": "Danmarkshavn",
                "value": "America/Danmarkshavn",
                "time": "02:55",
                "offset": "GMT +00:00"
            },
            {
                "label": "Scoresbysund",
                "value": "America/Scoresbysund",
                "time": "01:55",
                "offset": "GMT -01:00"
            },
            {
                "label": "Thule",
                "value": "America/Thule",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Grenada",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Guadeloupe",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Guam",
        "children": [
            {
                "label": "Guam",
                "value": "Pacific/Guam",
                "time": "12:55",
                "offset": "GMT +10:00"
            }
        ]
    },
    {
        "label": "Guatemala",
        "children": [
            {
                "label": "Guatemala",
                "value": "America/Guatemala",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Guernsey",
        "children": [
            {
                "label": "London",
                "value": "Europe/London",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Guinea",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Guinea-Bissau",
        "children": [
            {
                "label": "Bissau",
                "value": "Africa/Bissau",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Guyana",
        "children": [
            {
                "label": "Guyana",
                "value": "America/Guyana",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Haiti",
        "children": [
            {
                "label": "Port-au-Prince",
                "value": "America/Port-au-Prince",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Honduras",
        "children": [
            {
                "label": "Tegucigalpa",
                "value": "America/Tegucigalpa",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Hungary",
        "children": [
            {
                "label": "Budapest",
                "value": "Europe/Budapest",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Iceland",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Indonesia",
        "children": [
            {
                "label": "Jakarta",
                "value": "Asia/Jakarta",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Pontianak",
                "value": "Asia/Pontianak",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Makassar",
                "value": "Asia/Makassar",
                "time": "10:55",
                "offset": "GMT +08:00"
            },
            {
                "label": "Jayapura",
                "value": "Asia/Jayapura",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Iraq",
        "children": [
            {
                "label": "Baghdad",
                "value": "Asia/Baghdad",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Ireland",
        "children": [
            {
                "label": "Dublin",
                "value": "Europe/Dublin",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Isle of Man",
        "children": [
            {
                "label": "London",
                "value": "Europe/London",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Israel",
        "children": [
            {
                "label": "Jerusalem",
                "value": "Asia/Jerusalem",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Italy",
        "children": [
            {
                "label": "Rome",
                "value": "Europe/Rome",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Jamaica",
        "children": [
            {
                "label": "Jamaica",
                "value": "America/Jamaica",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Japan",
        "children": [
            {
                "label": "Tokyo",
                "value": "Asia/Tokyo",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Jersey",
        "children": [
            {
                "label": "London",
                "value": "Europe/London",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Jordan",
        "children": [
            {
                "label": "Amman",
                "value": "Asia/Amman",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Kazakhstan",
        "children": [
            {
                "label": "Almaty",
                "value": "Asia/Almaty",
                "time": "08:55",
                "offset": "GMT +06:00"
            },
            {
                "label": "Qyzylorda",
                "value": "Asia/Qyzylorda",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Qostanay",
                "value": "Asia/Qostanay",
                "time": "08:55",
                "offset": "GMT +06:00"
            },
            {
                "label": "Aqtobe",
                "value": "Asia/Aqtobe",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Aqtau",
                "value": "Asia/Aqtau",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Atyrau",
                "value": "Asia/Atyrau",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Oral",
                "value": "Asia/Oral",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Kenya",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Kiribati",
        "children": [
            {
                "label": "Tarawa",
                "value": "Pacific/Tarawa",
                "time": "14:55",
                "offset": "GMT +12:00"
            },
            {
                "label": "Kanton",
                "value": "Pacific/Kanton",
                "time": "15:55",
                "offset": "GMT +13:00"
            },
            {
                "label": "Kiritimati",
                "value": "Pacific/Kiritimati",
                "time": "16:55",
                "offset": "GMT +14:00"
            }
        ]
    },
    {
        "label": "Korea (North)",
        "children": [
            {
                "label": "Pyongyang",
                "value": "Asia/Pyongyang",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Korea (South)",
        "children": [
            {
                "label": "Seoul",
                "value": "Asia/Seoul",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Kuwait",
        "children": [
            {
                "label": "Riyadh",
                "value": "Asia/Riyadh",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Kyrgyzstan",
        "children": [
            {
                "label": "Bishkek",
                "value": "Asia/Bishkek",
                "time": "08:55",
                "offset": "GMT +06:00"
            }
        ]
    },
    {
        "label": "Laos",
        "children": [
            {
                "label": "Bangkok",
                "value": "Asia/Bangkok",
                "time": "09:55",
                "offset": "GMT +07:00"
            }
        ]
    },
    {
        "label": "Latvia",
        "children": [
            {
                "label": "Riga",
                "value": "Europe/Riga",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Lebanon",
        "children": [
            {
                "label": "Beirut",
                "value": "Asia/Beirut",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Lesotho",
        "children": [
            {
                "label": "Johannesburg",
                "value": "Africa/Johannesburg",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Liberia",
        "children": [
            {
                "label": "Monrovia",
                "value": "Africa/Monrovia",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Libya",
        "children": [
            {
                "label": "Tripoli",
                "value": "Africa/Tripoli",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Liechtenstein",
        "children": [
            {
                "label": "Zurich",
                "value": "Europe/Zurich",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Lithuania",
        "children": [
            {
                "label": "Vilnius",
                "value": "Europe/Vilnius",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Luxembourg",
        "children": [
            {
                "label": "Brussels",
                "value": "Europe/Brussels",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Madagascar",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Malawi",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Malaysia",
        "children": [
            {
                "label": "Kuching",
                "value": "Asia/Kuching",
                "time": "10:55",
                "offset": "GMT +08:00"
            },
            {
                "label": "Singapore",
                "value": "Asia/Singapore",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Maldives",
        "children": [
            {
                "label": "Maldives",
                "value": "Indian/Maldives",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Mali",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Malta",
        "children": [
            {
                "label": "Malta",
                "value": "Europe/Malta",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Marshall Islands",
        "children": [
            {
                "label": "Kwajalein",
                "value": "Pacific/Kwajalein",
                "time": "14:55",
                "offset": "GMT +12:00"
            },
            {
                "label": "Tarawa",
                "value": "Pacific/Tarawa",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Martinique",
        "children": [
            {
                "label": "Martinique",
                "value": "America/Martinique",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Mauritania",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Mauritius",
        "children": [
            {
                "label": "Mauritius",
                "value": "Indian/Mauritius",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Mayotte",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Mexico",
        "children": [
            {
                "label": "Mexico City",
                "value": "America/Mexico_City",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Cancun",
                "value": "America/Cancun",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Merida",
                "value": "America/Merida",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Monterrey",
                "value": "America/Monterrey",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Matamoros",
                "value": "America/Matamoros",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Chihuahua",
                "value": "America/Chihuahua",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Ciudad Juarez",
                "value": "America/Ciudad_Juarez",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Ojinaga",
                "value": "America/Ojinaga",
                "time": "21:55",
                "offset": "GMT -05:00"
            },
            {
                "label": "Mazatlan",
                "value": "America/Mazatlan",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Bahia Banderas",
                "value": "America/Bahia_Banderas",
                "time": "20:55",
                "offset": "GMT -06:00"
            },
            {
                "label": "Hermosillo",
                "value": "America/Hermosillo",
                "time": "19:55",
                "offset": "GMT -07:00"
            },
            {
                "label": "Tijuana",
                "value": "America/Tijuana",
                "time": "19:55",
                "offset": "GMT -07:00"
            }
        ]
    },
    {
        "label": "Micronesia",
        "children": [
            {
                "label": "Kosrae",
                "value": "Pacific/Kosrae",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Port Moresby",
                "value": "Pacific/Port_Moresby",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Guadalcanal",
                "value": "Pacific/Guadalcanal",
                "time": "13:55",
                "offset": "GMT +11:00"
            }
        ]
    },
    {
        "label": "Moldova",
        "children": [
            {
                "label": "Chisinau",
                "value": "Europe/Chisinau",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Monaco",
        "children": [
            {
                "label": "Paris",
                "value": "Europe/Paris",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Mongolia",
        "children": [
            {
                "label": "Ulaanbaatar",
                "value": "Asia/Ulaanbaatar",
                "time": "10:55",
                "offset": "GMT +08:00"
            },
            {
                "label": "Hovd",
                "value": "Asia/Hovd",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Choibalsan",
                "value": "Asia/Choibalsan",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Montenegro",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Montserrat",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Morocco",
        "children": [
            {
                "label": "Casablanca",
                "value": "Africa/Casablanca",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Mozambique",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Myanmar (Burma)",
        "children": [
            {
                "label": "Yangon",
                "value": "Asia/Yangon",
                "time": "09:25",
                "offset": "GMT +06:30"
            }
        ]
    },
    {
        "label": "Namibia",
        "children": [
            {
                "label": "Windhoek",
                "value": "Africa/Windhoek",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Nauru",
        "children": [
            {
                "label": "Nauru",
                "value": "Pacific/Nauru",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Nepal",
        "children": [
            {
                "label": "Kathmandu",
                "value": "Asia/Kathmandu",
                "time": "08:40",
                "offset": "GMT +05:45"
            }
        ]
    },
    {
        "label": "Netherlands",
        "children": [
            {
                "label": "Brussels",
                "value": "Europe/Brussels",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "New Caledonia",
        "children": [
            {
                "label": "Noumea",
                "value": "Pacific/Noumea",
                "time": "13:55",
                "offset": "GMT +11:00"
            }
        ]
    },
    {
        "label": "Nicaragua",
        "children": [
            {
                "label": "Managua",
                "value": "America/Managua",
                "time": "20:55",
                "offset": "GMT -06:00"
            }
        ]
    },
    {
        "label": "Niger",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Nigeria",
        "children": [
            {
                "label": "Lagos",
                "value": "Africa/Lagos",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Niue",
        "children": [
            {
                "label": "Niue",
                "value": "Pacific/Niue",
                "time": "15:55",
                "offset": "GMT -11:00"
            }
        ]
    },
    {
        "label": "Norfolk Island",
        "children": [
            {
                "label": "Norfolk",
                "value": "Pacific/Norfolk",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "North Macedonia",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Northern Mariana Islands",
        "children": [
            {
                "label": "Guam",
                "value": "Pacific/Guam",
                "time": "12:55",
                "offset": "GMT +10:00"
            }
        ]
    },
    {
        "label": "Norway",
        "children": [
            {
                "label": "Berlin",
                "value": "Europe/Berlin",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Oman",
        "children": [
            {
                "label": "Dubai",
                "value": "Asia/Dubai",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Pakistan",
        "children": [
            {
                "label": "Karachi",
                "value": "Asia/Karachi",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Palau",
        "children": [
            {
                "label": "Palau",
                "value": "Pacific/Palau",
                "time": "11:55",
                "offset": "GMT +09:00"
            }
        ]
    },
    {
        "label": "Palestine",
        "children": [
            {
                "label": "Gaza",
                "value": "Asia/Gaza",
                "time": "04:55",
                "offset": "GMT +02:00"
            },
            {
                "label": "Hebron",
                "value": "Asia/Hebron",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Panama",
        "children": [
            {
                "label": "Panama",
                "value": "America/Panama",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Papua New Guinea",
        "children": [
            {
                "label": "Port Moresby",
                "value": "Pacific/Port_Moresby",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Bougainville",
                "value": "Pacific/Bougainville",
                "time": "13:55",
                "offset": "GMT +11:00"
            }
        ]
    },
    {
        "label": "Paraguay",
        "children": [
            {
                "label": "Asuncion",
                "value": "America/Asuncion",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Peru",
        "children": [
            {
                "label": "Lima",
                "value": "America/Lima",
                "time": "21:55",
                "offset": "GMT -05:00"
            }
        ]
    },
    {
        "label": "Philippines",
        "children": [
            {
                "label": "Manila",
                "value": "Asia/Manila",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Pitcairn",
        "children": [
            {
                "label": "Pitcairn",
                "value": "Pacific/Pitcairn",
                "time": "18:55",
                "offset": "GMT -08:00"
            }
        ]
    },
    {
        "label": "Poland",
        "children": [
            {
                "label": "Warsaw",
                "value": "Europe/Warsaw",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Portugal",
        "children": [
            {
                "label": "Lisbon",
                "value": "Europe/Lisbon",
                "time": "02:55",
                "offset": "GMT +00:00"
            },
            {
                "label": "Madeira",
                "value": "Atlantic/Madeira",
                "time": "02:55",
                "offset": "GMT +00:00"
            },
            {
                "label": "Azores",
                "value": "Atlantic/Azores",
                "time": "01:55",
                "offset": "GMT -01:00"
            }
        ]
    },
    {
        "label": "Puerto Rico",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Qatar",
        "children": [
            {
                "label": "Qatar",
                "value": "Asia/Qatar",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Romania",
        "children": [
            {
                "label": "Bucharest",
                "value": "Europe/Bucharest",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Russia",
        "children": [
            {
                "label": "Kaliningrad",
                "value": "Europe/Kaliningrad",
                "time": "04:55",
                "offset": "GMT +02:00"
            },
            {
                "label": "Moscow",
                "value": "Europe/Moscow",
                "time": "05:55",
                "offset": "GMT +03:00"
            },
            {
                "label": "Simferopol",
                "value": "Europe/Simferopol",
                "time": "05:55",
                "offset": "GMT +03:00"
            },
            {
                "label": "Kirov",
                "value": "Europe/Kirov",
                "time": "05:55",
                "offset": "GMT +03:00"
            },
            {
                "label": "Volgograd",
                "value": "Europe/Volgograd",
                "time": "05:55",
                "offset": "GMT +03:00"
            },
            {
                "label": "Astrakhan",
                "value": "Europe/Astrakhan",
                "time": "06:55",
                "offset": "GMT +04:00"
            },
            {
                "label": "Saratov",
                "value": "Europe/Saratov",
                "time": "06:55",
                "offset": "GMT +04:00"
            },
            {
                "label": "Ulyanovsk",
                "value": "Europe/Ulyanovsk",
                "time": "06:55",
                "offset": "GMT +04:00"
            },
            {
                "label": "Samara",
                "value": "Europe/Samara",
                "time": "06:55",
                "offset": "GMT +04:00"
            },
            {
                "label": "Yekaterinburg",
                "value": "Asia/Yekaterinburg",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Omsk",
                "value": "Asia/Omsk",
                "time": "08:55",
                "offset": "GMT +06:00"
            },
            {
                "label": "Novosibirsk",
                "value": "Asia/Novosibirsk",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Barnaul",
                "value": "Asia/Barnaul",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Tomsk",
                "value": "Asia/Tomsk",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Novokuznetsk",
                "value": "Asia/Novokuznetsk",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Krasnoyarsk",
                "value": "Asia/Krasnoyarsk",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Irkutsk",
                "value": "Asia/Irkutsk",
                "time": "10:55",
                "offset": "GMT +08:00"
            },
            {
                "label": "Chita",
                "value": "Asia/Chita",
                "time": "11:55",
                "offset": "GMT +09:00"
            },
            {
                "label": "Yakutsk",
                "value": "Asia/Yakutsk",
                "time": "11:55",
                "offset": "GMT +09:00"
            },
            {
                "label": "Khandyga",
                "value": "Asia/Khandyga",
                "time": "11:55",
                "offset": "GMT +09:00"
            },
            {
                "label": "Vladivostok",
                "value": "Asia/Vladivostok",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Ust-Nera",
                "value": "Asia/Ust-Nera",
                "time": "12:55",
                "offset": "GMT +10:00"
            },
            {
                "label": "Magadan",
                "value": "Asia/Magadan",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Sakhalin",
                "value": "Asia/Sakhalin",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Srednekolymsk",
                "value": "Asia/Srednekolymsk",
                "time": "13:55",
                "offset": "GMT +11:00"
            },
            {
                "label": "Kamchatka",
                "value": "Asia/Kamchatka",
                "time": "14:55",
                "offset": "GMT +12:00"
            },
            {
                "label": "Anadyr",
                "value": "Asia/Anadyr",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Rwanda",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Réunion",
        "children": [
            {
                "label": "Dubai",
                "value": "Asia/Dubai",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Samoa (American)",
        "children": [
            {
                "label": "Pago Pago",
                "value": "Pacific/Pago_Pago",
                "time": "15:55",
                "offset": "GMT -11:00"
            }
        ]
    },
    {
        "label": "Samoa (western)",
        "children": [
            {
                "label": "Apia",
                "value": "Pacific/Apia",
                "time": "15:55",
                "offset": "GMT +13:00"
            }
        ]
    },
    {
        "label": "San Marino",
        "children": [
            {
                "label": "Rome",
                "value": "Europe/Rome",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Sao Tome & Principe",
        "children": [
            {
                "label": "Sao Tome",
                "value": "Africa/Sao_Tome",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Saudi Arabia",
        "children": [
            {
                "label": "Riyadh",
                "value": "Asia/Riyadh",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Senegal",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Serbia",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Seychelles",
        "children": [
            {
                "label": "Dubai",
                "value": "Asia/Dubai",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Sierra Leone",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Singapore",
        "children": [
            {
                "label": "Singapore",
                "value": "Asia/Singapore",
                "time": "10:55",
                "offset": "GMT +08:00"
            }
        ]
    },
    {
        "label": "Slovakia",
        "children": [
            {
                "label": "Prague",
                "value": "Europe/Prague",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Slovenia",
        "children": [
            {
                "label": "Belgrade",
                "value": "Europe/Belgrade",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Solomon Islands",
        "children": [
            {
                "label": "Guadalcanal",
                "value": "Pacific/Guadalcanal",
                "time": "13:55",
                "offset": "GMT +11:00"
            }
        ]
    },
    {
        "label": "Somalia",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "South Africa",
        "children": [
            {
                "label": "Johannesburg",
                "value": "Africa/Johannesburg",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "South Georgia & the South Sandwich Islands",
        "children": [
            {
                "label": "South Georgia",
                "value": "Atlantic/South_Georgia",
                "time": "00:55",
                "offset": "GMT -02:00"
            }
        ]
    },
    {
        "label": "South Sudan",
        "children": [
            {
                "label": "Juba",
                "value": "Africa/Juba",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Spain",
        "children": [
            {
                "label": "Madrid",
                "value": "Europe/Madrid",
                "time": "03:55",
                "offset": "GMT +01:00"
            },
            {
                "label": "Ceuta",
                "value": "Africa/Ceuta",
                "time": "03:55",
                "offset": "GMT +01:00"
            },
            {
                "label": "Canary",
                "value": "Atlantic/Canary",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Sri Lanka",
        "children": [
            {
                "label": "Colombo",
                "value": "Asia/Colombo",
                "time": "08:25",
                "offset": "GMT +05:30"
            }
        ]
    },
    {
        "label": "St Barthelemy",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "St Helena",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "St Kitts & Nevis",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "St Lucia",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "St Maarten (Dutch)",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "St Martin (French)",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "St Pierre & Miquelon",
        "children": [
            {
                "label": "Miquelon",
                "value": "America/Miquelon",
                "time": "00:55",
                "offset": "GMT -02:00"
            }
        ]
    },
    {
        "label": "St Vincent",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Sudan",
        "children": [
            {
                "label": "Khartoum",
                "value": "Africa/Khartoum",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Surilabel",
        "children": [
            {
                "label": "Paramaribo",
                "value": "America/Paramaribo",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Svalbard & Jan Mayen",
        "children": [
            {
                "label": "Berlin",
                "value": "Europe/Berlin",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Sweden",
        "children": [
            {
                "label": "Berlin",
                "value": "Europe/Berlin",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Switzerland",
        "children": [
            {
                "label": "Zurich",
                "value": "Europe/Zurich",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Syria",
        "children": [
            {
                "label": "Damascus",
                "value": "Asia/Damascus",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Tajikistan",
        "children": [
            {
                "label": "Dushanbe",
                "value": "Asia/Dushanbe",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Tanzania",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Thailand",
        "children": [
            {
                "label": "Bangkok",
                "value": "Asia/Bangkok",
                "time": "09:55",
                "offset": "GMT +07:00"
            }
        ]
    },
    {
        "label": "Togo",
        "children": [
            {
                "label": "Abidjan",
                "value": "Africa/Abidjan",
                "time": "02:55",
                "offset": "GMT +00:00"
            }
        ]
    },
    {
        "label": "Tokelau",
        "children": [
            {
                "label": "Fakaofo",
                "value": "Pacific/Fakaofo",
                "time": "15:55",
                "offset": "GMT +13:00"
            }
        ]
    },
    {
        "label": "Tonga",
        "children": [
            {
                "label": "Tongatapu",
                "value": "Pacific/Tongatapu",
                "time": "15:55",
                "offset": "GMT +13:00"
            }
        ]
    },
    {
        "label": "Trinidad & Tobago",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Tunisia",
        "children": [
            {
                "label": "Tunis",
                "value": "Africa/Tunis",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Turkey",
        "children": [
            {
                "label": "Istanbul",
                "value": "Europe/Istanbul",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Turkmenistan",
        "children": [
            {
                "label": "Ashgabat",
                "value": "Asia/Ashgabat",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Turks & Caicos Is",
        "children": [
            {
                "label": "Grand Turk",
                "value": "America/Grand_Turk",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Tuvalu",
        "children": [
            {
                "label": "Tarawa",
                "value": "Pacific/Tarawa",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "US minor outlying islands",
        "children": [
            {
                "label": "Pago Pago",
                "value": "Pacific/Pago_Pago",
                "time": "15:55",
                "offset": "GMT -11:00"
            },
            {
                "label": "Tarawa",
                "value": "Pacific/Tarawa",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Uganda",
        "children": [
            {
                "label": "Nairobi",
                "value": "Africa/Nairobi",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Ukraine",
        "children": [
            {
                "label": "Kyiv",
                "value": "Europe/Kyiv",
                "time": "04:55",
                "offset": "GMT +02:00"
            },
            {
                "label": "Simferopol",
                "value": "Europe/Simferopol",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "United Arab Emirates",
        "children": [
            {
                "label": "Dubai",
                "value": "Asia/Dubai",
                "time": "06:55",
                "offset": "GMT +04:00"
            }
        ]
    },
    {
        "label": "Uruguay",
        "children": [
            {
                "label": "Montevideo",
                "value": "America/Montevideo",
                "time": "23:55",
                "offset": "GMT -03:00"
            }
        ]
    },
    {
        "label": "Uzbekistan",
        "children": [
            {
                "label": "Samarkand",
                "value": "Asia/Samarkand",
                "time": "07:55",
                "offset": "GMT +05:00"
            },
            {
                "label": "Tashkent",
                "value": "Asia/Tashkent",
                "time": "07:55",
                "offset": "GMT +05:00"
            }
        ]
    },
    {
        "label": "Vanuatu",
        "children": [
            {
                "label": "Efate",
                "value": "Pacific/Efate",
                "time": "13:55",
                "offset": "GMT +11:00"
            }
        ]
    },
    {
        "label": "Vatican City",
        "children": [
            {
                "label": "Rome",
                "value": "Europe/Rome",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Venezuela",
        "children": [
            {
                "label": "Caracas",
                "value": "America/Caracas",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Vietnam",
        "children": [
            {
                "label": "Ho Chi Minh",
                "value": "Asia/Ho_Chi_Minh",
                "time": "09:55",
                "offset": "GMT +07:00"
            },
            {
                "label": "Bangkok",
                "value": "Asia/Bangkok",
                "time": "09:55",
                "offset": "GMT +07:00"
            }
        ]
    },
    {
        "label": "Virgin Islands (UK)",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Virgin Islands (US)",
        "children": [
            {
                "label": "Puerto Rico",
                "value": "America/Puerto_Rico",
                "time": "22:55",
                "offset": "GMT -04:00"
            }
        ]
    },
    {
        "label": "Wallis & Futuna",
        "children": [
            {
                "label": "Tarawa",
                "value": "Pacific/Tarawa",
                "time": "14:55",
                "offset": "GMT +12:00"
            }
        ]
    },
    {
        "label": "Western Sahara",
        "children": [
            {
                "label": "El Aaiun",
                "value": "Africa/El_Aaiun",
                "time": "03:55",
                "offset": "GMT +01:00"
            }
        ]
    },
    {
        "label": "Yemen",
        "children": [
            {
                "label": "Riyadh",
                "value": "Asia/Riyadh",
                "time": "05:55",
                "offset": "GMT +03:00"
            }
        ]
    },
    {
        "label": "Zambia",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Zimbabwe",
        "children": [
            {
                "label": "Maputo",
                "value": "Africa/Maputo",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    },
    {
        "label": "Åland Islands",
        "children": [
            {
                "label": "Helsinki",
                "value": "Europe/Helsinki",
                "time": "04:55",
                "offset": "GMT +02:00"
            }
        ]
    }
]