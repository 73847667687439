<template>
  <div>
    <el-card class="header">
      <div slot="header" class="flex flex-between flex-v-center header">
        <h3>{{ $store.state.mode }}-{{ $t('个人资料') }}</h3>
      </div>

      <div class="margin-lt flex flex-h-center">

        <el-upload :headers="headers" accept="image/jpeg,image/jpg,image/png" :show-file-list="false"
          class="upload-demo" :action="$host + '/api/user/avatar'" :on-success="uploadSuccess" :limit="1">
          <div slot="trigger" class="avatar-box">
            <el-image class="avatar" :src="$store.state.user.avatar ? $store.state.user.avatar.indexOf('http') == 0 ? $store.state.user.avatar : $host + $store.state.user.avatar
              : '/web/image/logo0.png'">
            </el-image>
          </div>
        </el-upload>

      </div>


      <div class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">User ID:</div>
        <div class="mt margin-x lr">
          H{{ $store.state.user.id + 100000 }}
        </div>
      </div>

      <div class=" margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('用户昵称') }}:</div>
        <div class="mt margin-x lr flex flex-between flex-v-center">{{ $store.state.user.nickName || '无' }}<i
            @click="edit" class="el-icon-edit pointer" style="font-size:20px"></i></div>
      </div>


      <div class=" margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('微信') }}:</div>
        <div class="mt margin-x lr flex flex-between flex-v-center">{{ $store.state.user.unionid ? $t('已绑定') : $t('未绑定')
          }}

          <el-link v-if="!$store.state.user.unionid" @click="dialogVisible = true" size="small"
            type="primary">{{ $t('去绑定') }}<i class="el-icon-right"></i></el-link>
        </div>
      </div>


      <div class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('绑定手机') }}:</div>
        <div class="mt margin-x lr">{{ $store.state.user.phone }} <el-link @click="bindPhone()" size="small"
            type="primary">{{ $store.state.user.phone ? $t('更换手机号') : $t('去绑定') }}<i
              class="el-icon-right"></i></el-link></div>
      </div>

      <div class=" margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">Apple ID:</div>
        <div class="mt margin-x lr flex flex-between flex-v-center">{{ $store.state.user.apple_openId ? $t('已绑定') :
          $t('未绑定') }}
        </div>
      </div>


      <div class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">PTE{{ $t('学生认证') }}:</div>
        <div class="mt margin-x lr">

          <el-tag type="info" v-if="($store.state.user.certificate.status == 0)">{{ $t('认证中') }}</el-tag>
          <el-tag v-else-if="($store.state.user.certificate.status == 1)">{{ $t('已认证') }}</el-tag>
          <el-tag v-else>{{ $t('未认证') }}</el-tag>
        </div>
      </div>




      <div class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">CCL{{ $t('学生认证') }}:</div>
        <div class="mt margin-x lr">

          <el-tag type="info" v-if="($store.state.user.ccl_certificate.status == 0)">{{ $t('认证中') }}</el-tag>
          <el-tag v-else-if="($store.state.user.ccl_certificate.status == 1)">{{ $t('已认证') }}</el-tag>
          <el-tag v-else>{{ $t('未认证') }}</el-tag>
        </div>
      </div>

      <div class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('时区设置') }}:</div>
        <div class="mt margin-x lr">
          <el-cascader style="width: 100%;" @change="changeTimeZone" v-model="timezone" size="small" :options="options"
            :props="{ expandTrigger: 'hover', emitPath: false }"></el-cascader>
        </div>
      </div>






      <div v-if="$store.state.mode == 'PTE'" class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('VIP到期时间') }}:</div>
        <div class="mt margin-x lr">
          <el-tag v-if="($store.state.user.vip_endtime == null)">{{ $t('未升级') }}</el-tag>
          <el-tag type="primary" v-else>{{ $store.state.user.vip_endtime }}</el-tag>
        </div>
      </div>


      <div v-if="$store.state.mode == 'CCL'" class="margin-lt flex flex-h-center mt flex-v-center">
        <div class="mt margin-x ll">{{ $t('VIP到期时间') }}:</div>
        <div class="mt margin-x lr">
          <el-tag v-if="($store.state.user.ccl_endtime == null)">{{ $t('未升级') }}</el-tag>
          <el-tag type="primary" v-else>{{ $store.state.user.ccl_endtime }}</el-tag>
        </div>
      </div>

    </el-card>

    <el-dialog :visible.sync="dialogVisible" width="480px" center>
      <div style="height: 400px;">
        <iframe :src="src" width="100%" height="100%" frameborder="0"></iframe>
      </div>
    </el-dialog>



    <el-dialog :visible.sync="dialogVisible1" top="15%" width="480px" center>
      <h3 slot="title">{{ $store.state.user.phone ? $t('更换手机号') : $t('绑定手机号') }}</h3>
      <el-form ref="form" :model="form" label-width="0">
        <el-form-item>
          <el-input :placeholder="form.phone_type == '+61' ? $t('请输入手机号码,比如: 0451507793') : $t('请输入手机号码')"
            v-model="form.phone" class="input-with-select">
            <el-select style="width:160px" v-model="form.phone_type" slot="prepend" placeholder="">
              <el-option :label="$t('+86（中国）')" value="+86"></el-option>
              <el-option :label="$t('+61（澳大利亚）')" value="+61"></el-option>
              <el-option :label="$t('+62（印度尼西亚）')" value="+62"></el-option>
              <el-option :label="$t('+60（马来西亚）')" value="+60"></el-option>
              <el-option :label="$t('+91（印度）')" value="+91"></el-option>
              <el-option :label="$t('+63（菲律宾）')" value="+63"></el-option>
              <el-option :label="$t('+1（加拿大）')" value="+1"></el-option>
              <el-option :label="$t('+64（新西兰）')" value="+64"></el-option>
              <el-option :label="$t('+886（台湾）')" value="+886"></el-option>
              <el-option :label="$t('+852（香港）')" value="+852"></el-option>
              <el-option :label="$t('+853（澳门）')" value="+853"></el-option>
              <el-option :label="$t('+44（英国）')" value="+44"></el-option>
              <el-option :label="$t('+81（日本）')" value="+81"></el-option>
              <el-option :label="$t('+82（韩国）')" value="+82"></el-option>
              <el-option :label="$t('+001（美国）')" value="+001"></el-option>
              <el-option :label="$t('+65（新加坡）')" value="+65"></el-option>
              <el-option :label="$t('+66（泰国）')" value="+66"></el-option>
              <el-option :label="$t('+84（越南）')" value="+84"></el-option>
            </el-select>
          </el-input>
        </el-form-item>


        <el-form-item>
          <div class="flex flex-between">
            <el-input v-model="form.code" :placeholder="$t('请输入验证码')"></el-input>
            <el-button type="primary" @click="sendCode" style="margin-left: 20px;">{{ second == 0 ? $t('获取验证码') : second
              + $t('秒')
              }}</el-button>
          </div>
        </el-form-item>

      </el-form>

      <el-button slot="footer" class="block" round type="primary" @click="submit">{{ $store.state.user.phone ? $t('更换')
        : $t('绑定')
        }}</el-button>
    </el-dialog>

  </div>
</template>
<script>
import timezone from '../../common/timezone';
export default {
  data() {
    return {
      timezone: "",
      second: 0,
      form: {
        tag: "bind",
        phone_type: "+86",
        phone: '',
        code: ''
      },
      dialogVisible1: false,
      dialogVisible: false,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      user: {}
    };
  },


  created() {
    console.log(timezone)
    let code = this.$route.query.code;
    if (code) {
      this.$http.post("/api/login/wechat", { code: code, tag: "bind" }).then((user) => {
        this.$store.commit("setUser", user);
        this.$message({
          message: this.$t('绑定成功') + '!',
          type: 'success'
        });
      });
    }
    this.timezone = this.$store.state.user.timezone;
  },

  computed: {

    options: function () {
      let list = [];
      timezone.forEach((row) => {
        let children = [];
        row.children.forEach((item) => {
          children.push({ value: item.value, label: item.label + "(" + item.offset + ")" })
        })
        let newRow = { label: row.label, children: children };
        list.push(newRow)
      })

      return list
    },

    src: () => {
      return "https://open.weixin.qq.com/connect/qrconnect?appid=wx62ed850a6918bf20&redirect_uri=" +
        encodeURIComponent("https://www.ptefighter.com/main/personal") +
        "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
    },
  },

  methods: {

    changeTimeZone(e) {
      this.$http.post("/api/user/save", { timezone: e }).then((user) => {
        this.$store.commit("setUser", user);
        this.$message({
          message: this.$t('修改成功') + '!',
          type: 'success'
        });
      })
    },

    submit() {
      if (this.form.phone.length == 0) {
        this.$message({
          message: this.$t('手机号码错误') + '!',
          type: 'primary'
        });
      } else if (this.form.code.length != 6) {
        this.$message({
          message: this.$t('验证码错误') + '!',
          type: 'primary'
        });
      } else {
        this.$http.post("/api/regist", this.form).then((user) => {
          this.$store.commit("setUser", user);
          this.$message({
            message: this.$t('绑定成功') + '!',
            type: 'success'
          });
        })
      }
    },

    timer(second) {
      if (second) this.second = second;
      let that = this;
      setTimeout(() => {
        if (that.second > 0) {
          that.second--;
          that.timer();
        }
      }, 1000);
    },

    sendCode() {
      if (this.form.phone && this.second == 0) {
        this.$http.post("/api/sms", { phone: this.form.phone, phone_type: this.form.phone_type }).then(() => {
          this.timer(120);
        })
      }
    },

    bindPhone() {
      this.dialogVisible1 = true;
    },

    uploadSuccess(user) {
      this.$store.commit("setUser", user);
    },
    edit() {
      this.$prompt('', this.$t('请修改昵称'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        inputPattern: /^.{2,16}$/,
        inputErrorMessage: this.$t('昵称最少2个字符')
      }).then(({ value }) => {
        this.$http.post("/api/user/save", { nickName: value }).then((user) => {
          this.$store.commit("setUser", user);
          this.$message({
            message: this.$t('修改成功') + '!',
            type: 'success'
          });
        })
      }).catch(() => {

      });
    }
  },
};
</script>

<style scoped>
.input-with-select>>>.el-input-group__prepend {
  background-color: #fff;
  color: #E6A23C;
}

.header>>>.el-card__header {
  background: rgb(67, 69, 82);
  color: rgb(233, 207, 164);
}

.avatar {
  width: 100px;
  width: 100px;
  border-radius: 50%;
}

.ll {
  width: 120px;
  text-align: right;
}

.lr {
  width: 256px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #ccc;
}

.avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.avatar-box:hover::after {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  font-size: 14px;
  content: "更换图像";
}
</style>
